import React from 'react'
import MainLayout from '../layouts/MainLayout'
import SalesView from '../views/SalesView'

const SalesPage = () => {
  return (
    <MainLayout className="sales">
      <SalesView />
    </MainLayout>
  )
}

export default SalesPage
