import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import SalesPage from '../ui/pages/SalesPage'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/de.json'

const Sales = props => (
  <>
    <HelmetComponent
      title={metaTags.salesTitle}
      description={metaTags.sales}
      page="de/sales"
    />
    <AppWrapper {...props} lang="de">
      <SalesPage />
    </AppWrapper>
  </>
)

export default Sales
