import React from 'react'
import AudienceView from './AudienceView'
import smImage from '../../assets/images/hero-sales-sm.png'
import mdImage from '../../assets/images/hero-sales-md.png'
import lgImage from '../../assets/images/hero-sales.png'

const salesItems = [
  { text: 'key-benefits:sales:item1' },
  { text: 'key-benefits:sales:item2' },
  { text: 'key-benefits:sales:item3' },
  { text: 'key-benefits:sales:item4' },
  { text: 'key-benefits:sales:item5' },
  { text: 'key-benefits:sales:item6' },
]

const SalesView = () => {
  return (
    <>
      <AudienceView
        page="sales"
        title="use-case:sales:title"
        subtitle="sales:name"
        buttonText="action:see-case-study"
        smImage={smImage}
        mdImage={mdImage}
        lgImage={lgImage}
        videoTitle="Aucta sales video"
        videoUrl="https://www.youtube.com/embed/sueY-YBfJ_U"
        items={salesItems}
        buttonTo="/alphr"
      />
    </>
  )
}

export default SalesView
